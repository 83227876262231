import type { Icon } from "@sopost/react-components/dist/types";
export function BlockIntro({ ...props }: Icon): JSX.Element {
  return (
    <svg
      className={props.className ?? ""}
      width={props.size ?? 24}
      height={props.size ?? 24}
      viewBox="0 0 24 24"
      role="img"
      aria-label="Thank you"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8647 19.5C15.9733 19.5 19.3044 16.0311 19.3044 11.75C19.3044 7.47134 15.9733 4 11.8647 4C7.7562 4 4.42505 7.47134 4.42505 11.75C4.42505 16.0311 7.7562 19.5 11.8647 19.5ZM11.8646 10.0625C11.1687 10.0625 10.6046 9.47488 10.6046 8.75C10.6046 8.02511 11.1687 7.4375 11.8646 7.4375C12.5604 7.4375 13.1245 8.02511 13.1245 8.75C13.1245 9.47488 12.5604 10.0625 11.8646 10.0625ZM13.1848 15.75H10.5449C10.3461 15.75 10.1849 15.5821 10.1849 15.375V14.625C10.1849 14.4179 10.3461 14.25 10.5449 14.25H10.9048V12.25H10.5449C10.3461 12.25 10.1849 12.0821 10.1849 11.875V11.125C10.1849 10.9179 10.3461 10.75 10.5449 10.75H12.4648C12.6636 10.75 12.8248 10.9179 12.8248 11.125V14.25H13.1848C13.3836 14.25 13.5447 14.4179 13.5447 14.625V15.375C13.5447 15.5821 13.3836 15.75 13.1848 15.75Z"
        fill={props.fill ?? "#4E8FF6"}
      />
    </svg>
  );
}
