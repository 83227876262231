import type { Icon } from "@sopost/react-components/dist/types";
export function GTM({ ...props }: Icon): JSX.Element {
  return (
    <>
      <svg
        className={props.className ?? ""}
        width={props.size ?? 24}
        height={props.size ?? 24}
        viewBox="0 0 24 24"
      >
        <g clipPath="url(#clip0_681_53540)">
          <path
            d="M20.7271 10.9387L13.0613 3.27292C12.4763 2.68792 11.5242 2.68792 10.9387 3.27292L3.27292 10.9387C2.68792 11.5237 2.68792 12.4758 3.27292 13.0613L10.9387 20.7271C11.2312 21.0196 11.6158 21.1658 12 21.1658C12.3842 21.1658 12.7688 21.0196 13.0613 20.7271L20.7271 13.0613C21.3121 12.4763 21.3121 11.5237 20.7271 10.9387ZM12 14.0833L9.91667 12L12 9.91667L14.0833 12L12 14.0833Z"
            fill="#4FC3F7"
          />
          <path
            d="M15.8525 6.06416L12 9.91666L14.0833 12L12 14.0833L15.8525 17.9358L20.7271 13.0612C21.3121 12.4762 21.3121 11.5242 20.7271 10.9387L15.8525 6.06416Z"
            fill="#2979FF"
          />
          <path
            d="M8.14752 17.9358L10.9388 20.7271C11.2313 21.0196 11.6159 21.1658 12 21.1658C12.3842 21.1658 12.7688 21.0196 13.0613 20.7271L15.8525 17.9358L12 14.0833L8.14752 17.9358Z"
            fill="#2962FF"
          />
        </g>
        <defs>
          <clipPath id="clip0_681_53540">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(2 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
