import type { Icon } from "@sopost/react-components/dist/types";
export function BlockPositiveNegative({ ...props }: Icon): JSX.Element {
  return (
    <svg
      className={props.className ?? ""}
      width={props.size ?? 118}
      height={props.size ?? 84}
      viewBox="0 0 118 84"
      role="img"
      aria-label="Feedback"
    >
      <path
        d="M77.5 30C71.1472 30 66 35.1472 66 41.5C66 47.8528 71.1472 53 77.5 53C83.8528 53 89 47.8528 89 41.5C89 35.1472 83.8528 30 77.5 30ZM81.2097 37.7903C82.0304 37.7903 82.6935 38.4534 82.6935 39.2742C82.6935 40.095 82.0304 40.7581 81.2097 40.7581C80.3889 40.7581 79.7258 40.095 79.7258 39.2742C79.7258 38.4534 80.3889 37.7903 81.2097 37.7903ZM73.7903 37.7903C74.6111 37.7903 75.2742 38.4534 75.2742 39.2742C75.2742 40.095 74.6111 40.7581 73.7903 40.7581C72.9696 40.7581 72.3065 40.095 72.3065 39.2742C72.3065 38.4534 72.9696 37.7903 73.7903 37.7903ZM82.8234 45.6827C81.5018 47.2685 79.5635 48.1774 77.5 48.1774C75.4365 48.1774 73.4982 47.2685 72.1766 45.6827C71.546 44.9268 72.6867 43.9808 73.3173 44.7321C74.356 45.9794 75.877 46.6889 77.5 46.6889C79.123 46.6889 80.644 45.9748 81.6827 44.7321C82.304 43.9808 83.4494 44.9268 82.8234 45.6827Z"
        fill={props.fill ?? "currentColor"}
        className="icon-highlight"
      />
      <path
        d="M40.5 30C34.1472 30 29 35.1472 29 41.5C29 47.8528 34.1472 53 40.5 53C46.8528 53 52 47.8528 52 41.5C52 35.1472 46.8528 30 40.5 30ZM44.2097 37.7903C45.0304 37.7903 45.6935 38.4534 45.6935 39.2742C45.6935 40.095 45.0304 40.7581 44.2097 40.7581C43.3889 40.7581 42.7258 40.095 42.7258 39.2742C42.7258 38.4534 43.3889 37.7903 44.2097 37.7903ZM36.7903 37.7903C37.6111 37.7903 38.2742 38.4534 38.2742 39.2742C38.2742 40.095 37.6111 40.7581 36.7903 40.7581C35.9696 40.7581 35.3065 40.095 35.3065 39.2742C35.3065 38.4534 35.9696 37.7903 36.7903 37.7903ZM44.6827 47.9085C43.644 46.6657 42.1183 45.9516 40.5 45.9516C38.8817 45.9516 37.356 46.6657 36.3173 47.9085C35.6913 48.6643 34.5506 47.7137 35.1766 46.9579C36.4982 45.3766 38.4411 44.4677 40.5 44.4677C42.5589 44.4677 44.5018 45.3766 45.8188 46.9625C46.4494 47.7137 45.3087 48.6643 44.6827 47.9085Z"
        fill={props.fill ?? "currentColor"}
        className="icon-highlight"
      />
    </svg>
  );
}
