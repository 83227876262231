import { Icon } from "../../types";
export function BlockFacebook({ ...props }: Icon): JSX.Element {
  return (
    <>
      <svg
        className={props.className ?? ""}
        width={props.size ?? 24}
        height={props.size ?? 24}
        viewBox="0 0 64 64"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32 7C45.8071 7 57 18.1929 57 32C57 44.4782 47.8579 54.8208 35.9062 56.6963V39.2266H41.7314L42.8398 32H35.9062V27.3104C35.9062 25.3333 36.8748 23.4062 39.9804 23.4062H43.1328V17.2539C43.1328 17.2539 40.2718 16.7656 37.5366 16.7656C31.8261 16.7656 28.0938 20.2266 28.0938 26.4922V32H21.7461V39.2266H28.0938V56.6963C16.1421 54.8208 7 44.4782 7 32C7 18.1929 18.1929 7 32 7Z"
          fill={props.fill ?? `currentColor`}
        />
      </svg>
    </>
  );
}
