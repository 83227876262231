import { Change, compressChanges } from "../../builder/types/change";
import { PathElement } from "../../builder/types/path";
import { Spec } from "../../builder/types/specification";
import { Value } from "../../builder/types/value";
import { pathIsPrefix, pathMatch } from "../../utils";
import { Journey } from "../../types";
import _ from "lodash";
import { Activity, Maybe, Template } from "../../graph/generated";
import { Branding, BrandingActionTypes } from "../branding/state";

export type PreviewDetails = {
  id: string;
  url: URL | null;
  type: string;
};

// TODO: Can we narrow this down to be more precise?
export type Environment = { [key: string]: string | number | [string] };

type Font = { font: Value; spec: Spec };
export type Fonts = Font[];

export type Config = {
  id: string;
  type: string;
  spec: Spec;
  value: Value | undefined;
  fragment?: string;
};

export type MaybeBuilderState = "Loading" | BuilderState;

export type BuilderState = {
  currentConfig: string;
  selectedJourney?: Journey;
  previewContent: string;
  journeys?: Journey[];
  sidebarHidden: boolean;
  previewMode: string;
  changes: Change[];
  type: string;
  configs: { [key: string]: Config };
  viewPath: PathElement[];
  viewPathHistory: Array<PathElement[]>;
  previewDetails: PreviewDetails;
  environment: Environment;
  errors: ValidationError[];
  warnings: PathElement[][];
  selectedTabs: { [key: string]: number | undefined };
  endUserLocale: string;
  showAdvancedFields: boolean;
  currentlyViewedError?: PathElement[];
  templates: Maybe<Array<Maybe<Template>>>;
  fonts: Fonts;
  activityFlows: string;
  branding: Branding;
  activity: Activity | undefined;
  campaignBrandingEnabled?: boolean;
};

export type ValidationError = {
  path: string[];
  message: string;
  type?: "error" | "warning";
  title?: string;
};

export const defaultBuilderState: MaybeBuilderState = "Loading";

export enum BuilderActionTypes {
  InitState,
  PushChanges,
  CacheChanges,
  SetViewPath,
  ClearChangesList,
  SetPreviewDetails,
  SetTemplates,
  SetFonts,
  SetActivityFlows,
  UpdatePreview,
  SetPreviewHtml,
  ChangeEnv,
  SetErrors,
  SetWarnings,
  BackViewPath,
  SetSelectedTab,
  SetPreviewOrderDate,
  SetVersion,
  SetCurrentlyViewedError,
  UpdatePreviewState,
  SetBranding,
  SetCampaignBrandingEnabled,
}

export type BuilderAction =
  | InitState
  | PushChangesAction
  | CacheChangesAction
  | SetViewPath
  | ClearChangesList
  | SetPreviewDetailsAction
  | SetTemplatesAction
  | SetFontsAction
  | SetActivityFlowsAction
  | UpdatePreviewAction
  | SetPreviewHtmlAction
  | ChangeEnvAction
  | SetErrors
  | SetWarnings
  | BackViewPath
  | SetSelectedTab
  | SetPreviewOrderDate
  | SetVersion
  | SetCurrentlyViewedError
  | UpdatePreviewState
  | SetBrandingAction
  | SetCampaignBrandingEnabled;

export type InitState = {
  type: BuilderActionTypes.InitState;
  config: Config;
  previewDetails: PreviewDetails;
  locale: string;
  activity: Activity | undefined;
};

export type PushChangesAction = {
  type: BuilderActionTypes.PushChanges;
  changes: Change[];
};

export type CacheChangesAction = {
  type: BuilderActionTypes.CacheChanges | BrandingActionTypes.CacheChanges;
  value: Value | undefined;
};

export type SetViewPath = {
  type: BuilderActionTypes.SetViewPath;
  viewPath: PathElement[];
};

export type ClearChangesList = {
  type: BuilderActionTypes.ClearChangesList;
};

export type SetPreviewDetailsAction = {
  type: BuilderActionTypes.SetPreviewDetails;
  previewDetails: PreviewDetails;
};

export type SetTemplatesAction = {
  type: BuilderActionTypes.SetTemplates;
  templates?: Maybe<Array<Maybe<Template>>>;
};

export type SetFontsAction = {
  type: BuilderActionTypes.SetFonts;
  fonts?: Fonts;
};

export type SetActivityFlowsAction = {
  type: BuilderActionTypes.SetActivityFlows;
  activityFlows?: string;
};

export type SetBrandingAction = {
  type: BuilderActionTypes.SetBranding;
  branding?: Branding;
};

type SetCampaignBrandingEnabled = {
  type: BuilderActionTypes.SetCampaignBrandingEnabled;
  is_enabled?: boolean;
};

export type UpdatePreviewAction = {
  type: BuilderActionTypes.UpdatePreview;
};

type SetPreviewHtmlAction = {
  type: BuilderActionTypes.SetPreviewHtml;
  html: string;
};

type ChangeEnvAction = {
  type: BuilderActionTypes.ChangeEnv;
  parameter: string;
  value: string | number | [string];
};

export type SetErrors = {
  type: BuilderActionTypes.SetErrors;
  errors: ValidationError[];
};

export type SetWarnings = {
  type: BuilderActionTypes.SetWarnings;
  warnings: PathElement[][];
};

export type BackViewPath = {
  type: BuilderActionTypes.BackViewPath;
};

export type SetSelectedTab = {
  type: BuilderActionTypes.SetSelectedTab;
  path: PathElement[];
  ix: number;
};

type SetPreviewOrderDate = {
  type: BuilderActionTypes.SetPreviewOrderDate;
  locale: string;
};

export type UpdatePreviewState = {
  type: BuilderActionTypes.UpdatePreviewState;
  locale: string;
  version: string;
};

type SetVersion = {
  type: BuilderActionTypes.SetVersion;
  activity: Activity | undefined;
};

export type SetCurrentlyViewedError = {
  type: BuilderActionTypes.SetCurrentlyViewedError;
  path: PathElement[];
};

function mapLoaded(
  state: MaybeBuilderState,
  f: (state: BuilderState) => BuilderState,
): MaybeBuilderState {
  if (state === "Loading") return "Loading";

  return f(state);
}

export function builderReducer(
  mstate: MaybeBuilderState,
  action: BuilderAction,
): MaybeBuilderState {
  switch (action.type) {
    case BuilderActionTypes.InitState:
      return initState(
        action.config,
        action.previewDetails,
        action.locale,
        action.activity,
      );

    case BuilderActionTypes.PushChanges:
      return mapLoaded(mstate, (state) => pushChanges(state, action.changes));

    case BuilderActionTypes.CacheChanges:
      return mapLoaded(mstate, (state) => cacheChanges(state, action.value));

    case BuilderActionTypes.SetViewPath:
      return mapLoaded(mstate, (state) => setViewPath(state, action.viewPath));

    case BuilderActionTypes.SetCurrentlyViewedError:
      return mapLoaded(mstate, (state) =>
        setCurrentlyViewedError(state, action.path),
      );

    case BuilderActionTypes.ClearChangesList:
      return mapLoaded(mstate, (state) => clearChangesList(state));

    case BuilderActionTypes.SetPreviewDetails:
      return mapLoaded(mstate, (state) =>
        setPreviewDetails(state, action.previewDetails),
      );

    case BuilderActionTypes.SetTemplates:
      return mapLoaded(mstate, (state) =>
        setTemplates(state, action.templates ?? []),
      );

    case BuilderActionTypes.SetFonts:
      return mapLoaded(mstate, (state) => setFonts(state, action.fonts ?? []));

    case BuilderActionTypes.SetActivityFlows:
      return mapLoaded(mstate, (state) =>
        setActivityFlows(state, action.activityFlows ?? ""),
      );

    case BuilderActionTypes.UpdatePreview:
      return mapLoaded(mstate, (state) => updatePreview(state));

    case BuilderActionTypes.ChangeEnv:
      return mapLoaded(mstate, (state) =>
        changeEnv(state, action.parameter, action.value),
      );

    case BuilderActionTypes.SetErrors:
      return mapLoaded(mstate, (state) => setErrors(state, action.errors));

    case BuilderActionTypes.SetWarnings:
      return mapLoaded(mstate, (state) => setWarnings(state, action.warnings));

    case BuilderActionTypes.SetBranding:
      return mapLoaded(mstate, (state) =>
        setBranding(state, action.branding ?? {}),
      );

    case BuilderActionTypes.SetCampaignBrandingEnabled:
      return mapLoaded(mstate, (state) =>
        setCampaignBrandingEnabled(state, action.is_enabled ?? false),
      );

    case BuilderActionTypes.BackViewPath:
      return mapLoaded(mstate, (state) => backViewPath(state));

    case BuilderActionTypes.SetSelectedTab:
      return mapLoaded(mstate, (state) =>
        setSelectedTab(state, action.path, action.ix),
      );

    case BuilderActionTypes.SetPreviewOrderDate:
      return mapLoaded(mstate, (state) =>
        setPreviewOrderDate(state, action.locale),
      );

    case BuilderActionTypes.UpdatePreviewState:
      return mapLoaded(mstate, (state) =>
        updatePreviewState(state, action.locale, action.version),
      );

    default:
      return mstate;
  }
}

const makeDate = (locale: string): string => {
  const today = new Date();

  const newLocale = locale.replace("_", "-");

  const day = today.getDate();
  const month = today.toLocaleString(newLocale, { month: "long" });
  const year = today.getFullYear();

  const dayFormatted = locale === "de_DE" ? `${day}.` : day;

  return `${dayFormatted} ${month} ${year}`;
};

function initState(
  config: Config,
  previewDetails: PreviewDetails,
  locale = "en_GB",
  activity: Activity | undefined,
): BuilderState {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    currentConfig: config.id,
    selectedJourney: undefined,
    previewContent: "",
    journeys: [],
    sidebarHidden: false,
    previewMode: "DESKTOP",
    changes: [],
    type: "",
    configs: {
      [config.id]: { ...config },
    },
    previewDetails: previewDetails,
    environment: {
      first_name: "Jane",
      last_name: "Doe",
      full_name: "Jane Doe",
      product_name: "Product Name",
      brand_name: "Cara Beauty",
      locale,
      order_id: "SO1689NZKVGMQ",
      order_date: makeDate("en_GB"),
      order_address: "{{ display_dummy_address }}",
      shipping_date: makeDate("en_GB"),
      delivery_date: "{{ delivery_date }}",
      pin: "{{ display_dummy_pin }}",
      version: "2.0.0",
    },
    errors: [],
    warnings: [],
    viewPath: [],
    viewPathHistory: [],
    selectedTabs: {},
    endUserLocale: "en_GB",
    showAdvancedFields: urlParams.get("show_advanced") === "true",
    templates: [],
    fonts: [],
    activityFlows: "{}",
    branding: {},
    activity: activity,
  };
}

/*
pushChanges adds a set of changes to an existing list of changes
in our state. When we submit changes for saving or preview, this
complete set of changes will be sent.
*/
function pushChanges(state: BuilderState, changes: Change[]): BuilderState {
  window.onbeforeunload = () => true; // Prompt user on navigation
  return {
    ...state,
    changes: compressChanges([...changes, ...state.changes]),
  };
}

/*
cacheChanges updates the `value` field of the builder with the
latest latest changes applied to the in-memory copy of the config.
On every change pushed to pushChanges, you could in theory rebuild
this purely from the list of changes, but it is calculated readily
with recursive update functions and we can think of it as a cache.
*/
function cacheChanges(
  state: BuilderState,
  value: Value | undefined,
): BuilderState {
  const { currentConfig, configs } = state;
  return {
    ...state,
    configs: {
      ...state.configs,
      [currentConfig]: {
        ...configs[currentConfig],
        value,
      },
    },
  };
}

export const debouncedPreviewUpdate = _.debounce(
  (state: BuilderState, viewPath: Array<PathElement>) => {
    const { currentConfig, configs, environment, previewDetails } = state;

    const urlParams = new URLSearchParams(previewDetails.url?.search);
    const params = Object.fromEntries(urlParams);
    sendIframeMessage(
      {
        tag: "UpdateConfig",
        data: {
          preview_config: configs[currentConfig].value,
          view_path: viewPath,
          env: { ...environment, ...params },
          type: configs[currentConfig].type,
        },
      },
      previewDetails.url,
    );
  },
  300,
);

function setViewPath(state: BuilderState, path: PathElement[]): BuilderState {
  debouncedPreviewUpdate(state, path);
  return {
    ...state,
    viewPath: path,
    viewPathHistory: [state.viewPath, ...state.viewPathHistory],
  };
}

function setCurrentlyViewedError(
  state: BuilderState,
  path: PathElement[],
): BuilderState {
  return {
    ...state,
    currentlyViewedError: path,
  };
}

function setErrors(
  state: BuilderState,
  errors: ValidationError[],
): BuilderState {
  return {
    ...state,
    errors: errors,
  };
}

function setWarnings(
  state: BuilderState,
  warnings: PathElement[][],
): BuilderState {
  return {
    ...state,
    warnings: warnings,
  };
}

function clearChangesList(state: BuilderState): BuilderState {
  window.onbeforeunload = null; // Disable navigation prompt
  return {
    ...state,
    changes: [],
  };
}

function setPreviewDetails(
  state: BuilderState,
  previewDetails: PreviewDetails,
): BuilderState {
  return {
    ...state,
    previewDetails: previewDetails,
  };
}

function setTemplates(
  state: BuilderState,
  templates: Maybe<Array<Maybe<Template>>>,
): BuilderState {
  return {
    ...state,
    templates: templates,
  };
}

function setFonts(state: BuilderState, fonts: Fonts): BuilderState {
  return {
    ...state,
    fonts: fonts,
  };
}

function setActivityFlows(
  state: BuilderState,
  activity_flows: string,
): BuilderState {
  return {
    ...state,
    activityFlows: activity_flows,
  };
}

function updatePreview(state: BuilderState): BuilderState {
  const { viewPath } = state;

  debouncedPreviewUpdate(state, viewPath);
  return { ...state };
}

function setBranding(state: BuilderState, branding: Branding): BuilderState {
  return {
    ...state,
    branding: branding,
  };
}

function setCampaignBrandingEnabled(
  state: BuilderState,
  is_enabled: boolean,
): BuilderState {
  return {
    ...state,
    campaignBrandingEnabled: is_enabled,
  };
}

function changeEnv(
  state: BuilderState,
  parameter: string,
  value: string | number | [string],
) {
  const updatedState = { ...state };
  updatedState.environment[parameter] = value;
  return { ...updatedState };
}

type UpdateConfigData = {
  tag: "UpdateConfig";
  data: {
    preview_config: Value | undefined;
    view_path: Array<PathElement>;
    env: Environment;
    type: string;
  };
};

type UpdateConfigMessage = {
  tag: "UpdateConfig";
  data: string;
};

function toUpdateMessage(msg: UpdateConfigData): UpdateConfigMessage {
  return { ...msg, data: JSON.stringify(msg.data) };
}

// TODO: Can we use this in place of all the other places that have an iframe message function?
function sendIframeMessage(
  msg: UpdateConfigData,
  remoteControlTarget: URL | null,
) {
  if (!remoteControlTarget) return;
  document
    .querySelector("iframe")
    ?.contentWindow?.window.postMessage(
      toUpdateMessage(msg),
      remoteControlTarget.href,
    );
}

function backViewPath(state: BuilderState): BuilderState {
  const path = state.viewPathHistory[0] ?? [];
  const pathHistory = state.viewPathHistory.slice(1);
  debouncedPreviewUpdate(state, path);
  return {
    ...state,
    viewPath: path,
    viewPathHistory: pathHistory,
  };
}

const mkSelectedTabKey = (path: PathElement[]): string =>
  path.length === 0 ? "__root" : path.join("/");

const setSelectedTab = (
  state: BuilderState,
  path: PathElement[],
  ix: number,
): BuilderState => {
  const newTabs = state.selectedTabs;
  newTabs[mkSelectedTabKey(path)] = ix;

  return {
    ...state,
    selectedTabs: newTabs,
  };
};

const setPreviewOrderDate = (
  state: BuilderState,
  locale: string,
): BuilderState => {
  return {
    ...state,
    environment: { ...state.environment, order_date: makeDate(locale) },
  };
};

const updatePreviewState = (
  state: BuilderState,
  locale: string,
  version: string,
): BuilderState => {
  return {
    ...state,
    environment: {
      ...state.environment,
      version: version,
      order_date: makeDate(locale),
    },
  };
};

export const getSelectedTab = (
  state: BuilderState,
  path: PathElement[],
): number => {
  return state.selectedTabs[mkSelectedTabKey(path)] ?? 0;
};

// Returns the first error from the state which matches the given path.
export function findError(
  path: PathElement[],
  state?: BuilderState,
): ValidationError | undefined {
  if (state === undefined) return undefined;

  const errs = state.errors;

  for (let i = 0; i < errs.length; i++) {
    const err = errs[i];
    if (pathMatch(err.path, path)) return err;
  }

  return undefined;
}

// Returns true if the given path is a prefix of any errors.
export function containsError(
  path: PathElement[],
  state?: BuilderState,
): boolean {
  if (state === undefined) return false;

  const errs = state.errors;
  for (let i = 0; i < errs.length; i++)
    if (pathIsPrefix(path, errs[i].path)) return true;

  return false;
}
