import type { Icon } from "@sopost/react-components/dist/types";
export function BlockSocialIcons({ ...props }: Icon): JSX.Element {
  return (
    <svg
      className={props.className ?? ""}
      width={props.size ?? 24}
      height={props.size ?? 24}
      viewBox="0 0 24 24"
      role="img"
      aria-label="Social icons"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 7.33333C20 9.17427 18.5076 10.6667 16.6667 10.6667C15.6019 10.6667 14.6537 10.1675 14.0435 9.39027L10.5534 11.1353C10.6273 11.4111 10.6667 11.7009 10.6667 12C10.6667 12.2991 10.6273 12.5889 10.5534 12.8647L14.0435 14.6097C14.6537 13.8325 15.6019 13.3333 16.6667 13.3333C18.5076 13.3333 20 14.8257 20 16.6667C20 18.5076 18.5076 20 16.6667 20C14.8257 20 13.3333 18.5076 13.3333 16.6667C13.3333 16.3676 13.3727 16.0777 13.4466 15.802L9.95653 14.0569C9.34627 14.8341 8.39807 15.3333 7.33333 15.3333C5.49239 15.3333 4 13.8409 4 12C4 10.1591 5.49239 8.66667 7.33333 8.66667C8.39807 8.66667 9.34627 9.16593 9.95653 9.94307L13.4466 8.198C13.3727 7.92227 13.3333 7.6324 13.3333 7.33333C13.3333 5.49239 14.8257 4 16.6667 4C18.5076 4 20 5.49239 20 7.33333ZM18.6667 7.33333C18.6667 8.43793 17.7713 9.33333 16.6667 9.33333C15.5621 9.33333 14.6667 8.43793 14.6667 7.33333C14.6667 6.22877 15.5621 5.33333 16.6667 5.33333C17.7713 5.33333 18.6667 6.22877 18.6667 7.33333ZM18.6667 16.6667C18.6667 17.7713 17.7713 18.6667 16.6667 18.6667C15.5621 18.6667 14.6667 17.7713 14.6667 16.6667C14.6667 15.5621 15.5621 14.6667 16.6667 14.6667C17.7713 14.6667 18.6667 15.5621 18.6667 16.6667ZM7.33333 14C8.43793 14 9.33333 13.1046 9.33333 12C9.33333 10.8954 8.43793 10 7.33333 10C6.22877 10 5.33333 10.8954 5.33333 12C5.33333 13.1046 6.22877 14 7.33333 14Z"
        fill={props.fill ?? `#846AED`}
      />
    </svg>
  );
}
