import type { Icon } from "@sopost/react-components/dist/types";
export function BlockStarRating({ ...props }: Icon): JSX.Element {
  return (
    <svg
      className={props.className ?? ""}
      width={props.size ?? 118}
      height={props.size ?? 84}
      viewBox="0 0 118 84"
      role="img"
      aria-label="Feedback"
    >
      <path
        fill={props.fill ?? "currentColor"}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6103 48.14L22.0597 46.3641L25.5091 48.14C26.1213 48.4554 26.8524 47.9487 26.7336 47.2688L26.0738 43.5076L28.8634 40.845C29.3648 40.3668 29.0877 39.5319 28.3963 39.4336L24.5405 38.883L22.8171 35.4605C22.5084 34.8504 21.6137 34.8426 21.3023 35.4605L19.5789 38.883L15.7231 39.4336C15.0317 39.5319 14.7546 40.3668 15.256 40.845L18.0456 43.5076L17.3858 47.2688C17.267 47.9487 17.9981 48.4579 18.6103 48.14Z"
        stroke="#A6A8AD"
        className="icon-highlight"
      />
      <path
        fill={props.fill ?? "currentColor"}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.4365 48.14L40.8859 46.3641L44.3352 48.14C44.9475 48.4554 45.6785 47.9487 45.5598 47.2688L44.9 43.5076L47.6896 40.845C48.191 40.3668 47.9139 39.5319 47.2224 39.4336L43.3667 38.883L41.6433 35.4605C41.3345 34.8504 40.4398 34.8426 40.1284 35.4605L38.4051 38.883L34.5493 39.4336C33.8579 39.5319 33.5807 40.3668 34.0822 40.845L36.8718 43.5076L36.212 47.2688C36.0932 47.9487 36.8242 48.4579 37.4365 48.14Z"
        stroke="#A6A8AD"
        className="icon-highlight"
      />
      <path
        fill={props.fill ?? "currentColor"}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.2617 48.14L59.7111 46.3641L63.1604 48.14C63.7727 48.4554 64.5037 47.9487 64.385 47.2688L63.7252 43.5076L66.5148 40.845C67.0162 40.3668 66.7391 39.5319 66.0476 39.4336L62.1919 38.883L60.4685 35.4605C60.1597 34.8504 59.265 34.8426 58.9536 35.4605L57.2303 38.883L53.3745 39.4336C52.683 39.5319 52.4059 40.3668 52.9074 40.845L55.6969 43.5076L55.0372 47.2688C54.9184 47.9487 55.6494 48.4579 56.2617 48.14Z"
        stroke="#A6A8AD"
        className="icon-highlight"
      />
      <path
        fill={props.fill ?? "currentColor"}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.0879 48.14L78.5372 46.3641L81.9866 48.14C82.5989 48.4554 83.3299 47.9487 83.2111 47.2688L82.5514 43.5076L85.3409 40.845C85.8424 40.3668 85.5653 39.5319 84.8738 39.4336L81.018 38.883L79.2947 35.4605C78.9859 34.8504 78.0912 34.8426 77.7798 35.4605L76.0564 38.883L72.2007 39.4336C71.5092 39.5319 71.2321 40.3668 71.7335 40.845L74.5231 43.5076L73.8633 47.2688C73.7446 47.9487 74.4756 48.4579 75.0879 48.14Z"
        stroke="#A6A8AD"
        className="icon-highlight"
      />
      <path
        fill={props.fill ?? "currentColor"}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.9141 48.14L97.3634 46.3641L100.813 48.14C101.425 48.4554 102.156 47.9487 102.037 47.2688L101.378 43.5076L104.167 40.845C104.669 40.3668 104.391 39.5319 103.7 39.4336L99.8442 38.883L98.1208 35.4605C97.8121 34.8504 96.9174 34.8426 96.606 35.4605L94.8826 38.883L91.0269 39.4336C90.3354 39.5319 90.0583 40.3668 90.5597 40.845L93.3493 43.5076L92.6895 47.2688C92.5707 47.9487 93.3018 48.4579 93.9141 48.14Z"
        stroke="#A6A8AD"
        className="icon-highlight"
      />
    </svg>
  );
}
