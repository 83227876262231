import { Icon } from "../../types";
export function BlockPinterest({ ...props }: Icon): JSX.Element {
  return (
    <>
      <svg
        className={props.className ?? ""}
        width={props.size ?? 24}
        height={props.size ?? 24}
        viewBox="0 0 64 64"
      >
        <path
          d="M31.9897 7C18.1682 7 7 18.1888 7 31.9897C7 42.582 13.5816 51.6318 22.8782 55.2723C22.652 53.2978 22.4669 50.2538 22.9605 48.0942C23.413 46.1402 25.8811 35.6713 25.8811 35.6713C25.8811 35.6713 25.1407 34.1698 25.1407 31.9691C25.1407 28.4932 27.1563 25.9016 29.6656 25.9016C31.8046 25.9016 32.833 27.5059 32.833 29.4187C32.833 31.5578 31.4755 34.7663 30.7557 37.7486C30.1592 40.2373 32.0103 42.2735 34.4578 42.2735C38.9005 42.2735 42.3147 37.5841 42.3147 30.8379C42.3147 24.8527 38.016 20.6775 31.8663 20.6775C24.7499 20.6775 20.5747 26.0045 20.5747 31.5166C20.5747 33.6557 21.3974 35.9592 22.4258 37.2139C22.6314 37.4607 22.652 37.6869 22.5903 37.9337C22.4052 38.7153 21.9733 40.4224 21.891 40.7721C21.7882 41.2246 21.5208 41.3274 21.0477 41.1012C17.9626 39.6203 16.0292 35.0543 16.0292 31.3932C16.0292 23.5158 21.747 16.276 32.545 16.276C41.204 16.276 47.9502 22.4463 47.9502 30.7145C47.9502 39.3323 42.5204 46.2636 34.9926 46.2636C32.4628 46.2636 30.0769 44.9473 29.2748 43.3842C29.2748 43.3842 28.0202 48.1559 27.7116 49.3282C27.1563 51.5084 25.6343 54.2233 24.6059 55.8893C26.9506 56.6092 29.4188 57 32.0103 57C45.8112 57 57 45.8111 57 32.0102C56.9794 18.1888 45.7906 7 31.9897 7Z"
          fill={props.fill ?? `currentColor`}
        />
      </svg>
    </>
  );
}
