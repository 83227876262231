import { Icon } from "../../types";
export function BlockX({ ...props }: Icon): JSX.Element {
  return (
    <>
      <svg
        className={props.className ?? ""}
        width={props.size ?? 24}
        height={props.size ?? 24}
        viewBox="0 0 64 64"
      >
        <path
          d="M36.1617 28.1607L54.403 7H50.0804L34.2414 25.3736L21.5909 7H7L26.1301 34.7841L7 56.9743H11.3229L28.0492 37.5712L41.4091 56.9743H56L36.1606 28.1607H36.1617ZM30.2409 35.0289L28.3026 32.2622L12.8804 10.2475H19.5201L31.966 28.0141L33.9043 30.7807L50.0824 53.8745H43.4428L30.2409 35.0299V35.0289Z"
          fill={props.fill ?? `currentColor`}
        />
      </svg>
    </>
  );
}
