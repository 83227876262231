import { Icon } from "../../types";
export function BlockHTML({ ...props }: Icon): JSX.Element {
  return (
    <>
      <svg
        className={props.className ?? ""}
        width={props.size ?? 24}
        height={props.size ?? 24}
        viewBox="0 0 24 24"
      >
        <path
          d="M8.69998 15.9007L4.79998 12.0007L8.69998 8.1007C9.08998 7.7107 9.08998 7.0907 8.69998 6.7007C8.30998 6.3107 7.68998 6.3107 7.29998 6.7007L2.70998 11.2907C2.31998 11.6807 2.31998 12.3107 2.70998 12.7007L7.29998 17.3007C7.68998 17.6907 8.30998 17.6907 8.69998 17.3007C9.08998 16.9107 9.08998 16.2907 8.69998 15.9007ZM15.3 15.9007L19.2 12.0007L15.3 8.1007C14.91 7.7107 14.91 7.0907 15.3 6.7007C15.69 6.3107 16.31 6.3107 16.7 6.7007L21.29 11.2907C21.68 11.6807 21.68 12.3107 21.29 12.7007L16.7 17.3007C16.31 17.6907 15.69 17.6907 15.3 17.3007C14.91 16.9107 14.91 16.2907 15.3 15.9007Z"
          fill={props.fill ?? `currentColor`}
        />
      </svg>
    </>
  );
}
