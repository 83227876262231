import type { Icon } from "@sopost/react-components/dist/types";
export function BlockRepeatFeedback({ ...props }: Icon): JSX.Element {
  return (
    <svg
      className={props.className ?? ""}
      width={props.size ?? 24}
      height={props.size ?? 24}
      viewBox="0 0 24 24"
      role="img"
      aria-label="Repeat Feedback"
    >
      <path
        d="M7.09176 6.9997H17.0918V8.7897C17.0918 9.2397 17.6318 9.4597 17.9418 9.1397L20.7318 6.3497C20.9318 6.1497 20.9318 5.8397 20.7318 5.6397L17.9418 2.8497C17.6318 2.5397 17.0918 2.7597 17.0918 3.2097V4.9997H6.09176C5.54176 4.9997 5.09176 5.4497 5.09176 5.9997V9.9997C5.09176 10.5497 5.54176 10.9997 6.09176 10.9997C6.64176 10.9997 7.09176 10.5497 7.09176 9.9997V6.9997ZM17.0918 16.9997H7.09176V15.2097C7.09176 14.7597 6.55176 14.5397 6.24176 14.8597L3.45176 17.6497C3.25176 17.8497 3.25176 18.1597 3.45176 18.3597L6.24176 21.1497C6.55176 21.4597 7.09176 21.2397 7.09176 20.7897V18.9997H18.0918C18.6418 18.9997 19.0918 18.5497 19.0918 17.9997V13.9997C19.0918 13.4497 18.6418 12.9997 18.0918 12.9997C17.5418 12.9997 17.0918 13.4497 17.0918 13.9997V16.9997Z"
        fill={props.fill ?? "#4E8FF6"}
      />
    </svg>
  );
}
